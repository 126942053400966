<template>
  <el-popconfirm
      confirm-button-text='Yes'
      cancel-button-text='No, Thanks'
      icon="el-icon-info"
      icon-color="red"
      :title="message || 'Are you sure about this?'"
      @confirm="onDelete()"
    >
      <el-button size="mini" type="danger"  slot="reference" class="" :class="klass">{{ label }} <i class="fa fa-trash"></i></el-button>
    </el-popconfirm>
</template>
<script>

export default {
  props: {
    label: {
      type: String,
      description: 'Heading Title',
      default: null
    },
    klass: {
      type: String,
      description: 'class name',
      default: null
    },
    message: {
      type: String,
      description: 'class name',
      default: 'Are you sure about this?'
    }
  },
  data() {
    return {
    
    };
  },
  methods: {
    onDelete() {
      this.$emit('onClick')
    }
  }
};
</script>

<style scoped>
.error-text {
  display: block;
}
</style>